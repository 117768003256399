<template>
  <div class="fluid" id="requestList" v-if="permission.read_perm == 1">
    <div>
      <div class="request-list-div">
        <v-card class="px-5 py-1">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h4 class="dmsCategoryTitle1">
                ARCHIVE MANAGEMENT SYSTEM
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="refresh"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
              <h4 class="dmsCategoryTitle2">
                List Dokumen
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="refresh"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div class="request-list-toolbar-1">
                <v-toolbar
                  class="request-list-toolbar-div"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-toolbar-title style="color:indigo;"
                    >List Arsip</v-toolbar-title
                  >
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <div v-if="isAdmin" style="margin-right:20px;">
                    <v-btn
                      v-if="permission.create_perm == 1"
                      @click="addArchive"
                      rounded
                      outlined
                      small
                      elevation="1"
                      color="indigo"
                      class="indigo--text"
                      style="font-size:12px;"
                    >
                      Tambah data
                    </v-btn>
                  </div>
                  <div v-if="isAdmin">
                    <div
                      v-if="permission.create_perm == 1"
                      style="display:flex;justify-content:center;align-items:center;"
                    >
                      <v-btn
                        @click="importArchive"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;margin-right:20px;"
                      >
                        Import
                      </v-btn>
                      <v-btn
                        v-if="selectedData.length === 0"
                        @click="exportAll"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px;"
                      >
                        Export Semua
                      </v-btn>
                      <v-btn
                        v-else
                        @click="exportFile"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="indigo"
                        class="indigo--text"
                        style="font-size:12px; margin-right:20px;"
                      >
                        Export Sekarang
                      </v-btn>
                      <v-btn
                        v-if="selectedData.length !== 0"
                        @click="cancelExport"
                        rounded
                        outlined
                        small
                        elevation="1"
                        color="red"
                        class="red--text"
                        style="font-size:12px;"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <div class="request-list-toolbar-1">
                <v-toolbar
                  class="request-list-toolbar-div"
                  flat
                  style="height: 40px;"
                >
                  <div v-if="permission.update_perm == 1" style="width: 120px">
                    <v-select
                      :disabled="selectedData.length === 0"
                      label="Action"
                      style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                      v-model="actionValue"
                      :items="action1"
                      item-text="name"
                      item-value="id"
                      :item-disabled="disableItem"
                      outlined
                      return-id
                      dense
                      @change="action"
                    >
                    </v-select>
                  </div>

                  <div
                    style="margin-right:10px;"
                    class="d-flex justify-center align-center"
                    v-if="
                      getUserProfile.level.find(
                        ({ id }) => id === '1' || id === '20' || id === '32'
                      ) !== undefined
                    "
                  >
                    <div style="width: 120px">
                      <v-select
                        v-if="
                          getUserProfile.level.find(({ id }) => id === '1') !==
                            undefined
                        "
                        label="Tipe"
                        style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                        v-model="paramAPI.doc_type"
                        :items="[
                          { id: '', name: 'All' },
                          { id: 1, name: 'Hard Copy' },
                          { id: 2, name: 'Soft Copy' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                      ></v-select>
                      <v-select
                        v-if="
                          getUserProfile.level.find(
                            ({ id }) => id === '20' || id === '32'
                          ) !== undefined
                        "
                        label="Tipe"
                        style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                        v-model="paramAPI.doc_type"
                        :items="[
                          { id: 1, name: 'Hard Copy' },
                          { id: 2, name: 'Soft Copy' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                      ></v-select>
                    </div>
                    <div style="width: 120px">
                      <v-select
                        label="Status"
                        style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                        v-model="paramAPI.status"
                        :items="[
                          { id: '', name: 'All' },
                          { id: 1, name: 'Available' },
                          { id: 2, name: 'Loaned' },
                          { id: 3, name: 'Gone' },
                          { id: 4, name: 'Broken' },
                          { id: 5, name: 'Expired' },
                          { id: 6, name: 'In process renewal' },
                          { id: 11, name: 'Released' },
                          { id: -1, name: 'Reject' }
                        ]"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-id
                        dense
                      ></v-select>
                    </div>
                    <div style="width: 120px">
                      <v-select
                        label="Company"
                        v-model="paramAPI.company_id"
                        :items="company"
                        style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                        item-text="name"
                        item-value="id"
                        return-id
                        dense
                        outlined
                      ></v-select>
                    </div>
                    <div style="width: 120px">
                      <v-select
                        label="Department"
                        v-model="paramAPI.dept_id"
                        :items="department"
                        style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                        item-text="name"
                        item-value="id"
                        return-id
                        dense
                        outlined
                      ></v-select>
                    </div>
                  </div>
                  <v-text-field
                    v-model="paramAPI.keyword"
                    append-icon="mdi-magnify"
                    label="Cari Dokumen"
                    type="search"
                    outlined
                    dense
                    @keyup.enter="searchEnter"
                    style="position: relative; top: 15px;"
                  >
                  </v-text-field>
                  <div
                    v-if="
                      getUserProfile.level.find(({ id }) => id === '1') !==
                        undefined
                    "
                    style="padding: 0 10px;"
                  >
                    <v-col
                      cols="12"
                      style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                    >
                      <p
                        style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                      >
                        Tampilkan file tersembunyi
                      </p>

                      <v-checkbox
                        true-value="1"
                        false-value=""
                        v-model="paramAPI.isArchive"
                        style=" margin:0 0 0 10px; height:25px; padding:0;"
                      ></v-checkbox>
                    </v-col>
                  </div>
                </v-toolbar>
              </div>
              <div class="request-list-toolbar-2">
                <v-expansion-panels flat accordion>
                  <v-expansion-panel v-for="(item, i) in 1" :key="i" flat>
                    <v-expansion-panel-header
                      class="d-flex flex-column align-items-start"
                      style="padding:0; margin:0;"
                    >
                      <v-toolbar
                        class="request-list-toolbar-2"
                        flat
                        style="height: 60px;"
                      >
                        <div v-if="isAdmin">
                          <v-btn
                            v-if="permission.create_perm == 1"
                            @click.stop="addArchive"
                            rounded
                            outlined
                            small
                            elevation="1"
                            color="indigo"
                            class="indigo--text"
                            style="font-size:12px;margin-right:10px;"
                          >
                            <v-icon size="20">mdi-plus</v-icon>
                          </v-btn>
                        </div>
                        <div v-if="isAdmin">
                          <div
                            v-if="permission.create_perm == 1"
                            style="display:flex;justify-content:center;align-items:center;"
                          >
                            <v-btn
                              @click.stop="importArchive"
                              rounded
                              outlined
                              small
                              elevation="1"
                              color="indigo"
                              class="indigo--text"
                              style="font-size:12px; margin-right:10px;"
                            >
                              <v-icon size="20">mdi-file-import-outline</v-icon>
                            </v-btn>
                            <v-btn
                              v-if="selectedData.length === 0"
                              @click.stop="exportAll"
                              rounded
                              outlined
                              small
                              elevation="1"
                              color="indigo"
                              class="indigo--text"
                              style="font-size:12px;"
                            >
                              <v-icon size="20" style="margin-right:5px;"
                                >mdi-export</v-icon
                              >
                              Export Semua
                            </v-btn>
                            <v-btn
                              v-else
                              @click.stop="exportFile"
                              rounded
                              outlined
                              small
                              elevation="1"
                              color="green"
                              class="green--text"
                              style="font-size:12px; margin-right:10px;"
                            >
                              <v-icon size="20" style="margin-right:5px;"
                                >mdi-export</v-icon
                              >
                              Export
                            </v-btn>
                            <v-btn
                              v-if="selectedData.length !== 0"
                              @click.stop="cancelExport"
                              small
                              outlined
                              rounded
                              elevation="1"
                              color="red"
                              class="red--text"
                              style="font-size:12px;"
                            >
                              <v-icon size="20">mdi-close</v-icon> Batal
                            </v-btn>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar
                        class="request-list-toolbar-2"
                        flat
                        style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
                      >
                        <div
                          v-if="permission.update_perm == 1"
                          style="width: 120px;margin-right:10px;"
                        >
                          <v-select
                            @click.stop
                            :disabled="selectedData.length === 0"
                            label="Action"
                            style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                            v-model="actionValue"
                            :items="action1"
                            item-text="name"
                            item-value="id"
                            :item-disabled="disableItem"
                            outlined
                            return-id
                            dense
                            @change="action"
                          ></v-select>
                        </div>
                        <v-text-field
                          @click.stop
                          v-model="paramAPI.keyword"
                          append-icon="mdi-magnify"
                          label="Cari Dokumen"
                          type="search"
                          outlined
                          dense
                          @keyup.enter="searchEnter"
                          style="position: relative; top: 15px; "
                        >
                        </v-text-field>
                      </v-toolbar>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content id="expansionPanelContent">
                      <v-toolbar flat style="height: 60px;">
                        <div
                          class="d-flex justify-center align-center"
                          v-if="
                            getUserProfile.level.find(
                              ({ id }) =>
                                id === '1' || id === '20' || id === '32'
                            ) !== undefined
                          "
                        >
                          <div style="width:50%;">
                            <v-select
                              label="Company"
                              v-model="paramAPI.company_id"
                              :items="company"
                              style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              outlined
                            ></v-select>
                          </div>
                          <div style="width:50%;">
                            <v-select
                              label="Department"
                              v-model="paramAPI.dept_id"
                              :items="department"
                              style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              outlined
                            ></v-select>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar flat>
                        <div
                          class="d-flex justify-center align-center"
                          v-if="
                            getUserProfile.level.find(
                              ({ id }) =>
                                id === '1' || id === '20' || id === '32'
                            ) !== undefined
                          "
                        >
                          <div style="width:50%;">
                            <v-select
                              v-if="
                                getUserProfile.level.find(
                                  ({ id }) => id === '1'
                                ) !== undefined
                              "
                              label="Tipe"
                              style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                              v-model="paramAPI.doc_type"
                              :items="[
                                { id: '', name: 'All' },
                                { id: 1, name: 'Hard Copy' },
                                { id: 2, name: 'Soft Copy' }
                              ]"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                            ></v-select>
                            <v-select
                              v-if="
                                getUserProfile.level.find(
                                  ({ id }) => id === '20' || id === '32'
                                ) !== undefined
                              "
                              label="Tipe"
                              style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                              v-model="paramAPI.doc_type"
                              :items="[
                                { id: 1, name: 'Hard Copy' },
                                { id: 2, name: 'Soft Copy' }
                              ]"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                            ></v-select>
                          </div>
                          <div style="width:50%;">
                            <v-select
                              label="Status"
                              style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                              v-model="paramAPI.status"
                              :items="[
                                { id: '', name: 'All' },
                                { id: 1, name: 'Available' },
                                { id: 2, name: 'Loaned' },
                                { id: 3, name: 'Gone' },
                                { id: 4, name: 'Broken' },
                                { id: 5, name: 'Expired' },
                                { id: 6, name: 'In process renewal' },
                                { id: 11, name: 'Released' },
                                { id: -1, name: 'Reject' }
                              ]"
                              item-text="name"
                              item-value="id"
                              outlined
                              return-id
                              dense
                            ></v-select>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar
                        flat
                        v-if="
                          getUserProfile.level.find(({ id }) => id === '1') !==
                            undefined
                        "
                      >
                        <v-col
                          cols="12"
                          style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                        >
                          <p
                            style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Tampilkan file tersembunyi
                          </p>

                          <v-checkbox
                            true-value="1"
                            false-value=""
                            v-model="paramAPI.isArchive"
                            style=" margin:0 0 0 10px; height:25px; padding:0;"
                          ></v-checkbox>
                        </v-col>
                      </v-toolbar>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-col>

            <v-col cols="12">
              <div style=" overflow:auto;">
                <v-data-table
                  mobile-breakpoint="0"
                  fixed-header
                  height="55vh"
                  v-model="selectedData"
                  style="cursor: pointer;min-width:1200px;"
                  :headers="headers"
                  :items="result"
                  :loading="loading"
                  @click:row="rowClick"
                  item-key="id"
                  :show-select="permission.update_perm == 1"
                  :options.sync="options"
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                  }"
                  :server-items-length="totalData"
                  @update:page="updatePage"
                  @update:items-per-page="updateItemPerPage"
                >
                  <template v-slot:header>
                    <div></div>
                  </template>
                  <template v-slot:[`item.archive_doc_no`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ item.archive_doc_no }}
                    </div>
                  </template>
                  <template v-slot:[`item.doc_no`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ item.doc_no }}
                    </div>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.document_type`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{
                        item.document_type.id === 3
                          ? 'SOP'
                          : item.document_type.name
                      }}
                    </div>
                  </template>
                  <template v-slot:[`item.doc_expiry_date`]="{ item }">
                    <td
                      class="arch-expDate"
                      style="font-size:12px; "
                      v-if="item.document_type_id !== 'Permanent'"
                    >
                      <div class="arch-exp-date">
                        <div
                          style="font-size:12px;  width: 120px; padding: 0 5px"
                        >
                          <p style="margin: 0">
                            {{
                              item.document_type_id === 'Permanent'
                                ? ''
                                : convertDate(item.doc_expiry_date)
                            }}
                          </p>
                        </div>

                        <div
                          class="arch-exp-date-notif"
                          v-if="countdown(item.doc_expiry_date) <= '7'"
                        >
                          <div class="exp-date-notif-div">
                            <v-chip
                              class="exp-date-notif-chip1"
                              v-if="countdown(item.doc_expiry_date) > '0'"
                              small
                              color="orange"
                              text-color="white"
                            >
                              Expire in
                              {{ countdown(item.doc_expiry_date) }} days
                            </v-chip>
                            <v-chip
                              class="exp-date-notif-chip2"
                              v-else
                              small
                              color="red"
                              text-color="white"
                            >
                              <p class="date-notif-chip2-p">Expired</p>
                            </v-chip>
                          </div>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ convertDate(item.created_at) }}
                    </div>
                  </template>
                  <template v-slot:[`item.created_by_name`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ item.created_by_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.category`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ item.category !== null ? item.category.name : '' }}
                    </div>
                  </template>
                  <template v-slot:[`item.plant`]="{ item }">
                    <div
                      style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                    >
                      {{ item.plant !== null ? singkat(item.plant) : '' }}
                    </div>
                  </template>
                  <template v-slot:[`item.doc_status`]="{ item }">
                    <div
                      :style="
                        `font-size:12px; padding: 0; display: flex; flex-direction: row;color:${status(
                          item.doc_status
                        )}`
                      "
                    >
                      {{ item.doc_status.name }}
                    </div>
                  </template>
                </v-data-table>
              </div>
              <div class="pr-items-add-new-line">
                <ArchiveAdd
                  :param="{
                    act: 'add'
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <ArchiveAdd
                  :param="{
                    act: 'update',
                    items: itemEdit
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <ArchiveAdd
                  :param="{
                    act: 'update',
                    items: itemEdit,
                    duplicate: true
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <DetailDocument
                  :result="detail"
                  @close="clear"
                  :permission="permission"
                />
              </div>
              <div class="pr-items-add-new-line">
                <ReturnDocument
                  :param="{
                    items: itemEdit
                  }"
                  @loadPage="getDataFromApi"
                  :permission="permission"
                  :isAdmin="isAdmin"
                />
              </div>
              <div class="pr-items-add-new-line">
                <ArchiveImport
                  @loadPage="getDataFromApi"
                  :permission="permission"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>

    <div
      v-if="loader"
      class="d-flex justify-center align-center"
      style="position:absolute;top:0;z-index:3; left:0;width:100%; height:100%;background:rgba(255,255,255,0.5);"
    >
      <v-progress-circular
        :size="150"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import XLSX from 'xlsx'
export default {
  components: {
    ArchiveAdd: () =>
      import('../../../components/archive-management/archive-manage/Form'),
    ReturnDocument: () =>
      import('../../../components/archive-management/archive-manage/Return'),
    DetailDocument: () =>
      import('../../../components/archive-management/archive-manage/Detail'),
    ArchiveImport: () =>
      import('../../../components/archive-management/archive-manage/Import')
  },
  data: () => ({
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    isAdmin: false,
    // menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 29 : 31,
    menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 31 : 31,
    permission: {},
    application: {
      id: 14,
      name: 'eArchive'
    },
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      sortBy: 'archive_doc_no',
      sortType: 'asc',
      company_id: '',
      dept_id: '',
      itemsPerPage: 10,
      doc_type: '',
      isArchive: ''
    },
    company: [],
    department: [],
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'No. Arsip',
        value: 'archive_doc_no',
        align: 'left',
        sortable: false,
        width: '10%'
      },
      {
        text: 'No. Dokumen',
        value: 'doc_no',
        align: 'left',
        sortable: false,
        width: '10%'
      },
      {
        text: 'Nama Dokumen',
        value: 'name',
        align: 'left',
        sortable: false,
        width: '37.5%'
      },
      {
        text: 'Tipe',
        value: 'document_type',
        align: 'left',
        sortable: false,
        width: '5%'
      },
      {
        text: 'Kategori',
        value: 'category',
        align: 'left',
        sortable: false,
        width: '10%'
      },
      {
        text: 'Kepemilikan',
        value: 'plant',
        align: 'left',
        sortable: false,
        width: '5%'
      },
      {
        text: 'Tgl Expired',
        value: 'doc_expiry_date',
        align: 'left',
        sortable: false,
        width: '5%'
      },
      {
        text: 'Created at',
        value: 'created_at',
        align: 'left',
        sortable: false,
        width: '5%'
      },
      {
        text: 'Created by',
        value: 'created_by_name',
        align: 'left',
        sortable: false,
        width: '7.5%'
      },
      {
        text: 'Status',
        value: 'doc_status',
        align: 'left',
        sortable: false,
        width: '5%'
      }
    ],
    result: [],
    selectedData: [],
    loading: false,
    itemEdit: null,
    detail: null,
    action1: [
      { id: 0, name: '' },
      { id: 1, name: 'Edit' },
      { id: 2, name: 'Return' },
      // { id: 3, name: 'History' },
      { id: 4, name: 'Delete' },
      { id: 5, name: 'Hide' },
      { id: 7, name: 'Unhide' },
      { id: 6, name: 'Duplicate' }
    ],
    action2: [
      { id: 0, name: '' },
      { id: 1, name: 'Edit' },
      { id: 4, name: 'Delete' },
      { id: 5, name: 'Hide' },
      { id: 7, name: 'Unhide' }
    ],
    loader: false
  }),
  watch: {
    'paramAPI.status'() {
      this.getDataFromApi()
    },
    'paramAPI.doc_type'() {
      this.getDataFromApi()
    },
    'paramAPI.company_id'() {
      this.getDataFromApi()
    },
    'paramAPI.dept_id'() {
      this.getDataFromApi()
    },
    'paramAPI.isArchive'() {
      this.getDataFromApi()
    }
  },
  created() {
    // a = approver_id | b = admin_id
    // const a = this.build === 'development' ? '19' : '20'
    const b = this.build === 'development' ? '20' : '20'
    var arr = this.getUserProfile.level
    console.log(arr)
    if (arr.find(({ id }) => id === '20' || id === '32') !== undefined) {
      this.paramAPI.doc_type = 1
    }
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            this.setPermission(result.data)
            // this.initDataTable()
            this.getDataFromApi()
            this.companyDropdown()
            this.departmentDropdown()
            if (arr[i].id === '1' || arr[i].id === b || arr[i].id === '32') {
              this.isAdmin = true
            } else {
              this.isAdmin = false
            }
          })
          .catch(error => {
            if (error) {
              this.isAdmin = false
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = false
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapMutations(['setPermission']),
    ...mapActions([
      'dropdownCompany',
      'dropdownDepartment',
      'dropdownUnit',
      'dropdownGlobalUnit',
      'dropdownDocStatus',
      'dropdownDocCategory',
      'menu_access',
      'dropdownPlant'
    ]),

    refresh() {
      this.getDataFromApi()
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.permission.update_perm === 1) {
            if (this.selectedData.length < 2) {
              this.initDropdown()
              this.itemEdit = this.selectedData[0]
              this.dropdownPlant()
              setTimeout(() => {
                document.getElementById('archiveEdit').click()
              }, 500)
            } else {
              this.showMsgDialog(
                'warning',
                'Pilih salah satu untuk edit',
                false
              )
              setTimeout(() => {
                this.selectedData = []
                this.actionValue = 0
              }, 200)
            }
          }
          break
        case 2:
          if (this.permission.update_perm === 1) {
            if (this.selectedData.length < 2) {
              if (this.selectedData[0].doc_status !== null) {
                if (this.selectedData[0].doc_status.id === 2) {
                  this.dropdownDocStatus()
                  axios
                    .get(
                      `${this.eSanqua}archive_document/detail/${this.selectedData[0].id}`
                    )
                    .then(res => {
                      if (res.data.data.loan_detail !== null) {
                        this.itemEdit = {
                          id: this.selectedData[0].id,
                          loaned_id: res.data.data.loan_detail.id
                        }
                        setTimeout(() => {
                          document.getElementById('archiveReturn').click()
                        }, 500)
                      } else {
                        this.showMsgDialog(
                          'warning',
                          'Detail peminjaman = Null',
                          false
                        )
                      }
                    })
                } else {
                  this.showMsgDialog(
                    'warning',
                    'Document is not in loaned state',
                    false
                  )
                  setTimeout(() => {
                    this.selectedData = []
                    this.actionValue = 0
                  }, 200)
                }
              } else {
                this.showMsgDialog(
                  'warning',
                  "Status dokumen 'Invalid' ",
                  false
                )
                setTimeout(() => {
                  this.selectedData = []
                  this.actionValue = 0
                }, 200)
              }
            } else {
              this.showMsgDialog(
                'warning',
                'Pilih salah satu untuk edit',
                false
              )
              setTimeout(() => {
                this.selectedData = []
                this.actionValue = 0
              }, 200)
            }
          }
          break
        case 3:
          alert('Cooming Soon')
          this.actionValue = 0
          break
        case 4:
          if (this.permission.delete_perm === 1) {
            this.delete()
          }
          break
        case 5:
          if (this.permission.delete_perm === 1) {
            this.hide()
          }
          break
        case 6:
          if (this.permission.update_perm === 1) {
            if (this.selectedData.length < 2) {
              this.initDropdown()
              this.itemEdit = this.selectedData[0]
              setTimeout(() => {
                document.getElementById('archiveDuplicate').click()
              }, 500)
            } else {
              this.showMsgDialog(
                'warning',
                'Pilih salah satu untuk edit',
                false
              )
              setTimeout(() => {
                this.selectedData = []
                this.actionValue = 0
              }, 200)
            }
          }
          break
        case 7:
          if (this.permission.delete_perm === 1) {
            this.unhide()
          }
          break
      }
    },
    searchEnter() {
      this.getDataFromApi()
    },

    getDataFromApi() {
      this.loading = true
      this.itemEdit = null
      this.actionValue = 0
      this.selectedData = []
      this.initDataTable().then(data => {
        this.result = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    initDataTable() {
      return new Promise(resolve => {
        axios
          .get(
            `${this.eSanqua}archive_document/list?is_archived=${
              this.paramAPI.isArchive
            }&keyword=${this.paramAPI.keyword}&doc_status_id=${
              this.paramAPI.status
            }&order_by=${this.paramAPI.sortBy}&order_type=${
              this.paramAPI.sortType
            }&plant_id=${this.paramAPI.company_id}&pic_department_id=${
              this.paramAPI.dept_id
            }&document_type_id=${this.paramAPI.doc_type}&offset=${this.paramAPI
              .offset * this.paramAPI.limit}&limit=${this.paramAPI.limit}`
          )
          .then(res => {
            // this.result = res.data.data
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      this.getDataFromApi()
    },
    updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      this.getDataFromApi()
    },
    addArchive() {
      this.initDropdown()
      this.dropdownPlant()
      setTimeout(() => {
        document.getElementById('archiveAdd').click()
      }, 500)
    },
    importArchive() {
      // setTimeout(() => {
      document.getElementById('archiveImport').click()
      // }, 500)
    },
    rowClick(pValue) {
      axios
        .get(`${this.eSanqua}archive_document/detail/${pValue.id}`)
        .then(res => {
          this.detail = res.data.data
          document.getElementById('archivedetail').click()
        })
        .catch(err => {
          console.log(err)
        })
    },
    delete() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan dihapus secara permanent, Anda yakin ?`,
        true
      ).then(res => {
        if (res.isConfirmed) {
          this.initDelete()
        } else {
          this.actionValue = 0
        }
      })
    },
    async initDelete() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .delete(
              `${this.eSanqua}archive_document/delete/${this.selectedData[i].id}`
            )
            .then(res => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch(err => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil terhapus <br/> ${arrFail.length} data gagal terhapus pada index [${arrFail}]`,
        false
      )
      this.getDataFromApi()
    },
    hide() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan disembunyikan, lanjutkan ?`,
        true
      ).then(res => {
        if (res.isConfirmed) {
          this.initHide()
        } else {
          this.actionValue = 0
        }
      })
    },
    async initHide() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .put(
              `${this.eSanqua}archive_document/archive/${this.selectedData[i].id}`,
              { is_delete: 1 }
            )
            .then(res => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch(err => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil disembunyikan <br/> ${arrFail.length} data gagal disembunyikan pada index [${arrFail}]`,
        false
      )
      this.getDataFromApi()
    },
    unhide() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan ditampilkan, lanjutkan ?`,
        true
      ).then(res => {
        if (res.isConfirmed) {
          this.initUnhide()
        } else {
          this.actionValue = 0
        }
      })
    },
    async initUnhide() {
      this.loader = true
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .put(
              `${this.eSanqua}archive_document/archive/${this.selectedData[i].id}`,
              { is_delete: 0 }
            )
            .then(res => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch(err => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.loader = false
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil ditampilkan <br/> ${arrFail.length} data gagal disembunyikan pada index [${arrFail}]`,
        false
      )
      this.getDataFromApi()
    },
    clear() {
      this.detail = null
    },
    initDropdown() {
      this.companyDropdown()
      this.departmentDropdown()
      this.dropdownUnit()
      this.dropdownGlobalUnit()
      this.dropdownDocStatus()
      this.dropdownDocCategory()
    },
    departmentDropdown() {
      this.department = [{ id: '', name: 'All' }]
      this.dropdownDepartment()
        .then(res => {
          console.log(res)
          for (let i = 0; i < res.data.length; i++) {
            this.department.push(res.data[i])
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    companyDropdown() {
      this.company = [{ id: '', name: 'All' }]
      this.dropdownCompany()
        .then(res => {
          console.log(res)
          for (let i = 0; i < res.data.length; i++) {
            this.company.push(res.data[i])
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        return raw.replace(/-/g, '/')
      } else {
        return raw
      }
    },
    countdown(raw) {
      if (raw !== null) {
        const day = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const year = raw.slice(raw.lastIndexOf('-') + 1)
        const z = new Date(year + '/' + month + '/' + day)

        const a = new Date()
        const n = a.toLocaleDateString('id')
        const day2 = n.slice(0, n.indexOf('/'))
        const month2 = n.slice(n.indexOf('/') + 1, n.lastIndexOf('/'))
        const year2 = n.slice(n.lastIndexOf('/') + 1)
        const x = new Date(year2 + '/' + month2 + '/' + day2)

        const diffInTime = z.getTime() - x.getTime()
        const q = diffInTime / (1000 * 3600 * 24)

        return q
      }
    },
    status(raw) {
      if (raw.id === 1) {
        return 'green'
      }
      if (raw.id === 2 || raw.id === 6) {
        return 'orange'
      }
      if (
        raw.id === -1 ||
        raw.id === 3 ||
        raw.id === 4 ||
        raw.id === 5 ||
        raw.id === 4 ||
        raw.id === 11
      ) {
        return 'red'
      }
    },
    singkat(raw) {
      let name = ''
      switch (raw.id) {
        case 1:
          name = 'TMP'
          break
        case 2:
          name = 'IMP'
          break
        case 3:
          name = 'GIT'
          break
        case 4:
          name = 'KAJ'
          break
        case 5:
          name = 'WIM'
          break
        case 6:
          name = 'SMI'
          break
        case 7:
          name = 'THP'
          break
        case 8:
          name = 'GAI'
          break
      }
      return name
    },

    exportAll() {
      axios
        .get(
          `${this.eSanqua}archive_document/list?is_archived=&keyword=${this.paramAPI.keyword}&doc_status_id=${this.paramAPI.status}&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}&plant_id=${this.paramAPI.company_id}&pic_department_id=${this.paramAPI.dept_id}&document_type_id=${this.paramAPI.doc_type}&offset=0&limit=all`
        )
        .then(res => {
          this.selectedData = []
          const response = res.data.data
          console.log(response)
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              this.selectedData.push({
                id: response[i].id,
                name: response[i].name,
                doc_no: response[i].doc_no,
                plant: response[i].plant,
                pic_department_name: response[i].pic_department_name,
                pic_department_id: response[i].pic_department_id,
                doc_date: response[i].doc_date,
                has_expire: response[i].has_expire,
                doc_expiry_date: response[i].doc_expiry_date,
                rack_index: response[i].rack_index,
                doc_status: response[i].doc_status,
                doc_qty: response[i].doc_qty,
                doc_qty_unit: response[i].doc_qty_unit,
                published_by: response[i].published_by,
                published_city: response[i].published_city,
                document_type: response[i].document_type,
                description: response[i].description,
                category: response[i].category,
                archive_doc_no: response[i].archive_doc_no
              })
            }
          } else {
            this.selectedData = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportFile() {
      this.showMsgDialog('question', 'Sertakan ID', true).then(res => {
        if (res.isConfirmed) {
          this.exportNow(1)
        } else if (res.dismiss === 'cancel') {
          this.exportNow(0)
        } else if (res.dismiss === 'backdrop') {
          this.selectedData = []
        }
      })
    },
    exportNow(num) {
      const arrData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        const param = {
          // id: this.selectedData[i].id,
          name: this.selectedData[i].name,
          doc_no: this.selectedData[i].doc_no,
          plant_name:
            this.selectedData[i].plant !== null
              ? this.selectedData[i].plant.name
              : '',
          plant_id:
            this.selectedData[i].plant !== null
              ? this.selectedData[i].plant.id
              : null,
          pic_department_name: this.selectedData[i].pic_department_name,
          pic_department_id: this.selectedData[i].pic_department_id,
          doc_date: this.selectedData[i].doc_date
            .split('-')
            .reverse()
            .join('-'),
          has_expire: this.selectedData[i].has_expire,
          doc_expiry_date: this.selectedData[i].doc_expiry_date
            .split('-')
            .reverse()
            .join('-'),
          rack_index: this.selectedData[i].rack_index,
          doc_status:
            this.selectedData[i].doc_status !== null
              ? this.selectedData[i].doc_status.name
              : '',
          doc_status_id:
            this.selectedData[i].doc_status !== null
              ? this.selectedData[i].doc_status.id
              : null,
          doc_qty: this.selectedData[i].doc_qty,
          doc_qty_unit:
            this.selectedData[i].doc_qty_unit !== null
              ? this.selectedData[i].doc_qty_unit.name
              : '',
          doc_qty_unit_id:
            this.selectedData[i].doc_qty_unit !== null
              ? this.selectedData[i].doc_qty_unit.id
              : null,
          published_by: this.selectedData[i].published_by,
          published_city: this.selectedData[i].published_city,
          document_type_id:
            this.selectedData[i].document_type !== null
              ? this.selectedData[i].document_type.id
              : null,
          description: this.selectedData[i].description,
          category_name:
            this.selectedData[i].category !== null
              ? this.selectedData[i].category.name
              : '',
          category_id:
            this.selectedData[i].category !== null
              ? this.selectedData[i].category.id
              : null
          // archive_doc_no: this.selectedData[i].archive_doc_no
        }
        if (num === 1) {
          Object.assign(param, {
            id: this.selectedData[i].id
          })
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'Arsip.xlsx')

      setTimeout(() => {
        this.selectedData = []
      }, 1000)
    },
    cancelExport() {
      this.selectedData = []
    },
    disableItem(item) {
      if (this.paramAPI.isArchive === '1') {
        return item.id === 5
      } else {
        return item.id === 7
      }
    }
  }
}
</script>
<style lang="scss">
#requestList {
  margin-bottom: 10px;
  .request-list-div {
    padding: 20px 15px 0;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .request-list-toolbar-1 {
      .request-list-toolbar-div {
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .request-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
#expansionPanelContent > * {
  padding: 0px;
}
.dmsCategoryTitle1 {
  font-weight: bold;
  margin: auto;
  color: indigo;
}
.dmsCategoryTitle2 {
  display: none;
}
@media (max-width: 768px) {
  #requestList {
    .request-list-div {
      .request-list-toolbar-1 {
        .request-list-toolbar-div {
          display: none;
        }
      }
      .request-list-toolbar-2 {
        display: block;
      }
    }
  }
  .dmsCategoryTitle1 {
    display: none;
  }
  .dmsCategoryTitle2 {
    display: block;
    font-weight: bold;
    margin: 0;
    color: indigo;
  }
}
</style>
